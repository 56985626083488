<template>
  <div class="row">
    <div class="col">
      <b-card title="Service Orders">
        <blueprint-data api-route-path="serviceorders" no-actions no-new :fields="fields" :where="where" :q="q">
          <template v-slot:search>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Status" label-for="status">
              <b-form-select id="status" v-model="status" @input="buildWhere">
                <option :value="null">
                  -
                </option>>
                <option value="NEW">
                  New
                </option>>
                <option value="CONFIRMED">
                  Confirmed
                </option>>
                <option value="ACTIVATED">
                  Active
                </option>>
                <option value="CANCELED">
                  Canceled
                </option>>
                <option value="CLOSED">
                  Closed
                </option>>
              </b-form-select>
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Services">
              <blueprint-search-input
                id="services"
                v-model="services"
                :multiple="true"
                placeholder="Type to search service"
                label="name"
                track-by="id"
                api-route-path="inventory/services"
                @input="buildWhere"
              />
            </b-form-group>
          </template>
          <template v-slot:status="{ item }">
            <b-badge v-if="item.status==='NEW'" variant="info">
              New
            </b-badge>
            <b-badge v-if="item.status==='CONFIRMED'" variant="warning">
              Confirmed
            </b-badge>
            <b-badge v-if="item.status==='CANCELED'" variant="danger">
              Canceled
            </b-badge>
            <b-badge v-if="item.status==='CLOSED'" variant="dark">
              Closed
            </b-badge>
            <b-badge v-if="item.status==='ACTIVATED'" variant="success">
              Activated
            </b-badge>
          </template>
          <template v-slot:ordered="{ item }">
            {{ item.ordered | moment('LL') }}
          </template>
          <template v-slot:activated="{ item }">
            {{ item.activated | moment('LL') }}
          </template>
          <template v-slot:end="{ item }">
            {{ item.end | moment('LL') }}
          </template>
          <template v-slot:type="{ item }">
            <b-badge v-if="item.service.type==='MONTH_SUBSCRIPTION'" variant="warning">
              Subscription
            </b-badge>
            <b-badge v-if="item.service.type==='MONTH_SUBSCRIPTION_PER_M2'" variant="warning">
              Subscription divided by squere meters
            </b-badge>
            <b-badge v-if="item.service.type==='PER_ORDER'" variant="info">
              Order
            </b-badge>
          </template>
          <template v-slot:serviceId="{ item }">
            {{ item.service.name }}
          </template>
          <template v-slot:bookingId="{ item }">
            <a href="#" @click.prevent="$router.push({ path: `/app/bookings/edit/${item.booking.id}`});">{{ item.booking.number }}</a> <br>
            {{ item.booking.firstname }} {{ item.booking.lastname }}
            <span v-if="item.booking.company">({{ item.booking.company.name }})</span>
          </template>
          <template v-slot:type="{ item }">
            <b-badge v-if="item.service.type==='MONTH_SUBSCRIPTION'" variant="warning">
              Subscription
            </b-badge>
            <b-badge v-if="item.service.type==='MONTH_SUBSCRIPTION_PER_M2'" variant="warning">
              Subscription divided by squere meters
            </b-badge>
            <b-badge v-if="item.service.type==='PER_ORDER'" variant="info">
              Order
            </b-badge>
          </template>
          <template v-slot:price_tax="{ item }">
            {{ parseFloat(parseFloat(item.price) + parseFloat(item.price) * parseFloat(item.tax.percentage)/100).toFixed(2) }}
          </template>
          <template v-slot:price="{ item }">
            {{ parseFloat(item.price).toFixed(2) }}
          </template>
        </blueprint-data>
      </b-card>
    </div>
  </div>
</template>

<script>
import utils from '../../../libs/utils.vue';

export default {
  data () {
    return {
      status: null,
      where: {},
      q: null,
      services: [],
      fields: [
        {
          key: 'serviceId',
          sortable: true,
          slot: true,
        },
        {
          key: 'bookingId',
          sortable: true,
          slot: true,
        },
        {
          key: 'price',
          sortable: true,
          slot: true,
        },
        {
          key: 'price_tax',
          sortable: true,
          slot: true,
        },
        {
          key: 'status',
          sortable: true,
          slot: true,
        },
        {
          key: 'type',
          sortable: true,
          slot: true,
        },
        {
          key: 'ordered',
          sortable: true,
          slot: true,
        },
        {
          key: 'activated',
          sortable: true,
          slot: true,
        },
        {
          key: 'end',
          label: 'Ended',
          sortable: true,
          slot: true,
        },
      ],
    };
  },
  methods: {
    buildWhere () {
      this.where = {
        ...utils.parseWhereItem('status', this.status),
        ...utils.parseWhereItem('serviceId', this.services),
      };
    },

  },
};
</script>
